import React, { useEffect, useState } from 'react'
import { API_LOGIN } from '../data/Api'
import styled from 'styled-components'
import { InnerLayout } from '../styles/Layouts'
import PrimaryButton from '../components/PrimaryButton'
import { StyledButton } from '../components/StyledButton'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { changeErr, changeName, changeToken } from '../app/features/authSlice'
import { useCookies } from 'react-cookie'
import Particle from '../components/Particle'
import { useHistory } from 'react-router-dom'

type AdminModalProps = {
  closeModal: (event: boolean) => void
  jwtTokenChange: (event: string) => void
}

function AdminModal({ closeModal, jwtTokenChange }: AdminModalProps): JSX.Element {
  const [inputField, setInputField] = useState({
    email: '',
    password: ''
  })
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookie, setCookie] = useCookies(['user', 'token'])
  const dispatch = useDispatch()
  const history = useHistory()

  const inputsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setInputField((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const openInscription = () => {
    closeModal(true)
    history.push('/inscription')
  }

  useEffect(() => {
    setTimeout(() => {
      jwtTokenChange('none')
    }, 2000)
  }, [])

  const SubmitAuth = () => {
    const body = JSON.stringify({
      user: {
        email: inputField.email,
        password: inputField.password
      }
    })

    axios.request({
      method: 'post',
      url: API_LOGIN,
      data: body,
      headers: { 'Content-Type': 'application/json' }
    })
      .then((response) => {
        dispatch(changeToken(response.headers['authorization']))
        dispatch(changeName(response.data.status.data.user.name))
        setCookie('token', response.headers['authorization']
          .substring(7, response.headers['authorization'].length))
        jwtTokenChange('ok')
      }).catch((err) => {
        dispatch(changeErr(err.response.data))
        jwtTokenChange('err')
      })
    closeModal(true)
  }

  return (
    <AdminModalStyled>
      <Particle/>
      <InnerLayout className="modal-section">
        <div className="form-info el">
          <form
            onSubmit={(e) => { e.preventDefault(); SubmitAuth() }}
            className="form-part"
          >
            <h4>Identification</h4>
            <div className="form-group">
              <label htmlFor="name">E-Mail</label>
              <input
                type="text"
                name="email"
                id="email"
                onChange={inputsHandler}
                value={inputField.email}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Mot de passe</label>
              <input
                type="password"
                name="password"
                id="password"
                onChange={inputsHandler}
                value={inputField.password}
              />
            </div>
            <div className="form-group">
              <PrimaryButton btn={'Identification'}/>
              <StyledButton className="button-secondary" type="button" onClick={openInscription}>Inscription</StyledButton>
            </div>
          </form>
        </div>
      </InnerLayout>
    </AdminModalStyled>
  )
}

const AdminModalStyled = styled.div`
  .button-secondary {
    margin-left: 20px;
  }
  .modal-section {
    //position: absolute;
    //display: block;
    //grid-template-columns: repeat(2, 1fr);
    //grid-gap: 3rem;
    //@media screen and (max-width:768px){
    //  grid-template-columns: repeat(1, 1fr);
    //}
    //margin: 50px;
    width: 90%;

    .el {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: auto;
      transform: translate(-50%, -50%);
    }

    .form-info {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 90%;

      h4 {
        padding: 1rem 0;
        font-size: 1.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
    }

    .form-part {
      width: 100%;

      h4 {
        padding: 1rem 0;
        font-size: 1.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }

      .form-group {
        margin-top: 2rem;
        position: relative;
        width: 100%;

        label {
          position: absolute;
          left: 20px;
          top: -17px;
          display: inline-block;
          background-color: var(--background-color);
          padding: 0 .5rem;
          color: inherit;
        }

        input {
          border: 1px solid var(--border-color-2);
          outline: none;
          background-color: transparent;
          padding: 10px 15px;
          width: 100%;
          color: inherit;
          border-radius: .2rem;
          background-color: transparent;
        }

        textarea {
          resize: none;
          background-color: transparent;
          border: 1px solid var(--border-color-2);
          outline: none;
          color: inherit;
          width: 100%;
          padding: .8rem 1rem;
          border-radius: .2rem;
        }
      }
    }
  }
`

export default AdminModal
