import React, { useState } from 'react'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import PrimaryButton from '../components/PrimaryButton'
import { AstroLayout } from '../styles/AstroLayout'
import axios from 'axios'
import { API_SIGNUP } from '../data/Api'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

function InscriptionPage(): JSX.Element {
  const [inputField, setInputField] = useState({
    email: '',
    password: '',
    passwordConfirmation: '',
    name: ''
  })
  const history = useHistory()

  const inputsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setInputField((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const SubmitInscription = () => {
    const body = JSON.stringify({
      user: {
        email: inputField.email,
        password: inputField.password,
        password_confirmation: inputField.passwordConfirmation,
        name: inputField.name
      }
    })

    axios.request({
      method: 'post',
      url: API_SIGNUP,
      data: body,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(() => {
        history.push('/confirmation-inscription')
      }).catch((err) => {
        console.error(err.response.data.status.message)
        toast.error(<p>Erreur de envoi des données "signup":<br />{err.response.data.status.message}</p>)
      })
  }

  return (
    <InscriptionPageStyled>
      <AstroLayout>
        <Title title={''} span={'Inscription'}/>
        <InnerLayout className="inscription-section">
          <div className="inscription-info">
            <form
              className="form-part"
              onSubmit={(e) => {
                e.preventDefault()
                SubmitInscription()
              }}
            >
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={inputsHandler}
                  value={inputField.email}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Mot de passe</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  onChange={inputsHandler}
                  value={inputField.password}
                />
              </div>
              <div className="form-group">
                <label htmlFor="passwordConfirmation">Mot de passe confirmation</label>
                <input
                  type="password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  onChange={inputsHandler}
                  value={inputField.passwordConfirmation}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name">Nom</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={inputsHandler}
                  value={inputField.name}
                />
              </div>
              <div className="form-group">
                <PrimaryButton btn={'S\'inscrire'} />
              </div>
            </form>
          </div>
        </InnerLayout>
      </AstroLayout>
    </InscriptionPageStyled>
  )
}

const InscriptionPageStyled = styled.div`
  .inscription-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .inscription-info {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;

      h4 {
        padding: 1rem 0;
        font-size: 1.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
    }

    .form-part {
      width: 100%;

      h4 {
        padding: 1rem 0;
        font-size: 1.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }

      .form-group {
        margin-top: 2rem;
        position: relative;
        width: 100%;

        label {
          position: absolute;
          left: 20px;
          top: -17px;
          display: inline-block;
          background-color: var(--background-color);
          padding: 0 .5rem;
          color: inherit;
        }

        input {
          border: 1px solid var(--border-color-2);
          outline: none;
          background-color: transparent;
          padding: 10px 15px;
          width: 100%;
          color: inherit;
          border-radius: .2rem;
          background-color: transparent;
        }

        textarea {
          resize: none;
          background-color: transparent;
          border: 1px solid var(--border-color-2);
          outline: none;
          color: inherit;
          width: 100%;
          padding: .8rem 1rem;
          border-radius: .2rem;
        }
      }
    }
  }
`

export default InscriptionPage
