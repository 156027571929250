import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AstroLayout } from '../styles/AstroLayout'
import { HomeLayout } from '../styles/HomeLayout'
import Particle from '../components/Particle'
import PrimaryButton from '../components/PrimaryButton'
import { useSelector } from 'react-redux'
import { selectErr } from '../app/features/authSlice'
import Loading from '../components/Loading'

function HomeLoginPage(): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  function openModal() {
    window.location.reload()
  }

  const err = useSelector(selectErr)
  return (
    !swLoaded ? <Loading /> : <HomeLoginPageStyled>
      <AstroLayout>
        <HomeLayout>
          <Particle/>
          <form onSubmit={(e) => {
            e.preventDefault()
            openModal()
          }} className="form-part">
            <div className="typography">
              <h1>Astrologie traditionnelle par <span>bressani.dev</span></h1>
              <br/>
              <h3>{err}</h3>
              <br/>
              <PrimaryButton btn={'Recharger la page'}/>
            </div>
          </form>
        </HomeLayout>
      </AstroLayout>
    </HomeLoginPageStyled>
  )
}

const HomeLoginPageStyled = styled.div`
`

export default HomeLoginPage
