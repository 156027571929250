import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import store from './app/store'
import { Provider } from 'react-redux'
import GlobalStyle from './styles/GlobalStyle'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
/*
  <React.StrictMode>
    <GlobalStyle/>
    <BrowserRouter>
      <CookiesProvider>
        <Provider store={store}>
          <App/>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  </React.StrictMode>

 */
const container = document.getElementById('container')

if (container) {
  const root = ReactDOM.createRoot(container)
  root.render(
    <BrowserRouter>
      <GlobalStyle/>
      <CookiesProvider>
        <Provider store={store}>
          <App/>
        </Provider>
      </CookiesProvider>
    </BrowserRouter>
  )
} else {
  console.error("No 'container' element found in the DOM")
}
