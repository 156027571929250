import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import PrimaryButton from '../components/PrimaryButton'
import { AstroLayout } from '../styles/AstroLayout'
import axios from 'axios'
import { API } from '../data/Api'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectToken } from '../app/features/authSlice'
import Loading from '../components/Loading'

function SaisieThemePage(): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)
  const [natal, setNatal] = useState({ date: '1984-04-01', time: '00:00', address: '', country: '', gmt: 999, lat: 0, lng: 0 })
  const [inputField, setInputField] = useState({
    date: '',
    time: '',
    address: '',
    country: ''
  })
  const history = useHistory()
  const token = useSelector(selectToken)

  useEffect(() => {
    if (token !== '') {
      httpGetNatal()
    }
  }, [])

  // watecher
  useEffect(() => {
    setInputField({
      date: natal.date,
      time: natal.time,
      address: natal.address,
      country: natal.country
    })
  }, [natal])

  const httpGetNatal = async() => {
    axios.request({
      method: 'get',
      url: API + 'chart/show',
      headers: { 'Content-Type': 'application/json', Authorization: token }
    }).then(async(response) => {
      const data = await response
      setNatal(data.data)
      setSwLoaded(true)
    }).catch(() => {
      setSwLoaded(true)
      // console.error(err)
      // toast.error(<p>Erreur de chargement des données "chart/show/":<br />{err.message}</p>)
    })
  }

  const inputsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setInputField((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const SubmitThemeAstral = () => {
    const body = JSON.stringify({
      chart: {
        date: inputField.date,
        time: inputField.time,
        address: inputField.address,
        country: inputField.country
      }
    })

    axios.request({
      method: 'post',
      url: API + 'chart/create',
      data: body,
      headers: { 'Content-Type': 'application/json', Authorization: token }
    })
      .then(() => {
        toast.done('Thème astral enregistré !')
        setTimeout(function() {
          history.push('/theme')
        }, 1000)
      }).catch((err) => {
        console.error(err.response.data.status.message)
        toast.error(<p>Erreur de envoi des données "chart/create":<br />{err.response.data.status.message}</p>)
      })
  }

  return (
    !swLoaded ? <Loading /> : <SaisieThemePageStyled>
      <AstroLayout>
        <Title title={'Saisie'} span={'thème astral'}/>
        <InnerLayout className="saisie-section">
          <div className="saisie-info">
            <form
              className="form-part"
              onSubmit={(e) => {
                e.preventDefault()
                SubmitThemeAstral()
              }}
            >
              <div className="form-group">
                <label htmlFor="date">Date de naissance</label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  onChange={inputsHandler}
                  value={inputField.date}
                />
              </div>
              <div className="form-group">
                <label htmlFor="time">Heure de naissance</label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  onChange={inputsHandler}
                  value={inputField.time}
                />
              </div>
              <div className="form-group">
                <label htmlFor="address">Lieu de naissance</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  onChange={inputsHandler}
                  value={inputField.address}
                />
              </div>
              <div className="form-group">
                <label htmlFor="country">Pays de naissance</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  onChange={inputsHandler}
                  value={inputField.country}
                />
              </div>
              <div className="form-group">
                <PrimaryButton btn={'Sauvegarder information du thème'} />
              </div>
            </form>
          </div>
        </InnerLayout>
      </AstroLayout>
    </SaisieThemePageStyled>
  )
}

const SaisieThemePageStyled = styled.div`
  .saisie-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .saisie-info {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width: 100%;

      h4 {
        padding: 1rem 0;
        font-size: 1.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
    }

    .form-part {
      width: 100%;

      h4 {
        padding: 1rem 0;
        font-size: 1.8rem;
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }

      .form-group {
        margin-top: 2rem;
        position: relative;
        width: 100%;

        label {
          position: absolute;
          left: 20px;
          top: -17px;
          display: inline-block;
          background-color: var(--background-color);
          padding: 0 .5rem;
          color: inherit;
        }

        input {
          border: 1px solid var(--border-color-2);
          outline: none;
          background-color: transparent;
          padding: 10px 15px;
          width: 100%;
          color: inherit;
          border-radius: .2rem;
          background-color: transparent;
        }

        textarea {
          resize: none;
          background-color: transparent;
          border: 1px solid var(--border-color-2);
          outline: none;
          color: inherit;
          width: 100%;
          padding: .8rem 1rem;
          border-radius: .2rem;
        }
      }
    }
  }
`

export default SaisieThemePage
