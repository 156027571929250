import styled from 'styled-components'
import React from 'react'
import Navbar from './Navbar'

type SidebarProps = {
    isNavToggle: boolean
    setNavToggle: (event: boolean) => void
    theme: string
}

function Sidebar({ isNavToggle, setNavToggle, theme }: SidebarProps): JSX.Element {
  return (
    <SideBarStyled className={isNavToggle ? 'Nav-toggle no-print' : 'no-print'}>
      <Navbar setNavToggle={setNavToggle} theme={theme}/>
    </SideBarStyled>
  )
}

const SideBarStyled = styled.div`
  position: fixed;
  width: 16.3rem;
  height: 100vh;
  background-color: var(--sidebar-color);
  overflow: hidden;
  transition: all .4s ease-in-out;
  @media screen and (max-width: 1200px) {
    transform: translateX(-100%);
    z-index: 20;
  }
  @media screen and (max-width: 330px) {
    width: 13rem;
  }
`

export default Sidebar
