import styled from 'styled-components'

export const HomeLayout = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  .typography {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }

    p {
      padding: 0.5rem 0 1rem 0;
      @media screen and (max-width: 600px) {
        font-size: 0.8rem;
      }
    }
  }
`
