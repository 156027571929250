import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import axios from 'axios'
import { API } from '../data/Api'
import { selectToken } from '../app/features/authSlice'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr-ch'
import Loading from '../components/Loading'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import sephirothEmpty from '../images/Svg/sephiroth-empty.svg'
import { AstroLayout } from '../styles/AstroLayout'
import { DateInterface } from '../interfaces/v1/Sephiroth'

type LeTirageSephirothPageProps = {
  theme: string
}

function LeTirageSephirothPage({ theme }: LeTirageSephirothPageProps): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)
  const [tirages, setTirages] = useState<DateInterface[]>([])
  const [cartes, setCartes] = useState([
    {
      id: 0,
      id_pos: 0,
      sens: true,
      dc10_ti_seph: { date: '' },
      da04_tarot_carte: { id: 0, nom: '', chiffre_romain: '', id_carte: 0, svg_light: '', svg_dark: '' }
    }
  ])
  const token = useSelector(selectToken)

  const inputsHandlerSelectTirage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    selectTiAstro(value)
  }

  const selectTiAstro = async(t: string) => {
    axios.request({
      method: 'get',
      url: API + 'sephiroth/show/' + t,
      headers: { 'Content-Type': 'application/json', Authorization: token }
    })
      .then(async(response) => {
        const data = await response.data
        setCartes(data)
      }).catch((err) => {
        setSwLoaded(true)
        console.error(err)
        toast.error(<p>Erreur de chargement des données "sephiroth/show/":<br />{err.message}</p>)
      })
  }

  const httpGetTirage = async() => {
    axios.request({
      method: 'get',
      url: API + 'sephiroth/all',
      headers: { 'Content-Type': 'application/json', Authorization: token }
    }).then(async(response) => {
      const data = await response
      setTirages(data.data)
      await selectTiAstro(data.data[data.data.length - 1].id)
    }).catch((err) => {
      setSwLoaded(true)
      console.error(err)
      toast.error(<p>Erreur de chargement des données "sephiroth/all":<br />{err.message}</p>)
    })
  }

  useEffect(() => {
    if (token !== '') {
      httpGetTirage().then(() => setSwLoaded(true))
    }
  }, [])

  useEffect(() => {
    console.log(tirages)
  }, [tirages])

  const tirageSelect = () => {
    moment.locale('fr-ch')
    return (
      <select
        name="tirage"
        id="tirage"
        onChange={inputsHandlerSelectTirage}
      >
        {
          [...tirages].reverse().map((m: DateInterface, i) => { // copie [...tirage] pour reverse
            const originalIndex = tirages.length - 1 - i
            const date = moment(m.date, 'YYYY-MM-DD').format('MM.YYYY')
            const d = date.toString()
            if (tirages.length === 1) {
              return (
                <option value={m.id}>Tirage {d}</option>
              )
            } else {
              return (
                <option value={m.id}>Tirage {originalIndex + 1} - {d}</option>
              )
            }
          })
        }
      </select>
    )
  }

  const carteLink = () => {
    const tab = [
      [10, 233],
      [70, 321],
      [70, 140],
      [150, 321],
      [150, 140],
      [225, 233],
      [270, 321],
      [270, 140],
      [350, 233],
      [440, 233]
    ]
    return (
      <div>
        { cartes.map((item, index) => {
          return (
            <a
              key={index}
              href="#"
              className={item.sens ? 'overlay-link-carte' : 'overlay-link-carte overlay-link-carte-a-l-envers'}
              rel="noopener noreferrer"
              title={item.da04_tarot_carte.chiffre_romain + ' ' + item.da04_tarot_carte.nom}
              style={{
                top: tab[index][0] + 'px',
                left: tab[index][1] + 'px',
                backgroundImage: `url('data:image/svg+xml;base64,${theme === 'light-mode' ? item.da04_tarot_carte.svg_light : item.da04_tarot_carte.svg_dark}')`
              }}
            >
            </a>
          )
        }
        )}
      </div>
    )
  }

  return (
    !swLoaded ? <Loading /> : <LeTirageSephirothStyled>
      <AstroLayout>
        <ToastContainer theme={theme === 'light-mode' ? 'light' : 'dark'}/>
        {/* Les maisons Here */}
        <Title title={'Le tirage'} span={'astrologique'}/>
        {/* Les maisons Here */}

        {/* Structure binaire */}
        <InnerLayout className="astro-section-sans-image">
          <div className="info-part-sans-image">
            <h4>Tirage Sephiroth</h4>
            <br/>
            <form onSubmit={(e) => {
              e.preventDefault()
            }} className="form-part">
              <div className="form-group">
                {tirageSelect()}
              </div>
            </form>
            <br/>
            <div
              className="image-part-sephiroth"
              style={{
                backgroundImage: `url(${sephirothEmpty})`
              }}
              title="Arbre Sephiroth"
            >
              { carteLink() }
            </div>
          </div>
        </InnerLayout>
        {/* Structure binaire Here */}
      </AstroLayout>
    </LeTirageSephirothStyled>
  )
}

const LeTirageSephirothStyled = styled.div`
  .image-part-sephiroth {
    position: relative;
    width: 500px;  /* Largeur du SVG grille 25 */
    height: 500px; /* Hauteur du SVG grille 25 */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0;
  }

  .overlay-link-carte {
    display: block;
    position: absolute;
    width: 38px;
    height: 76px;
    background-size: cover;
    text-decoration: none; /* Pour éliminer tout soulignement ou style par défaut des liens */
  }

  .overlay-link-carte-a-l-envers {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }
`

export default LeTirageSephirothPage
