import React from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import logo from '../images/Svg/logo.svg'
import logoBg from '../images/Svg/logobg.svg'
import logoBressaniDevLight from '../images/Svg/logo-bressanidev-light.svg'
import logoBressaniDevDark from '../images/Svg/logo-bressanidev-dark.svg'
import { useDispatch, useSelector } from 'react-redux'
import { changeErr, changeName, changeToken, selectName, selectToken } from '../app/features/authSlice'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { API_LOGOUT } from '../data/Api'
import { useCookies } from 'react-cookie'

type NavbarProps = {
    setNavToggle: (event: boolean) => void
    theme: string
}

function Navbar({ setNavToggle, theme }: NavbarProps): JSX.Element {
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie] = useCookies(['user', 'token'])
  const dispatch = useDispatch()
  const username = useSelector(selectName)
  const token = useSelector(selectToken)

  const logout = () => {
    axios.request({
      method: 'delete',
      url: API_LOGOUT,
      headers: { 'Content-Type': 'application/json', Authorization: token }
    })
      .then(() => {
        setCookie('token', '') // remove cookie
        dispatch(changeToken('')) // redux
        dispatch(changeName('')) // redux
        dispatch(changeErr('')) // redux
        window.location.reload()
      }).catch(() => {
        setCookie('token', '') // remove cookie
        dispatch(changeToken('')) // redux
        dispatch(changeName('')) // redux
        dispatch(changeErr('')) // redux
      })
  }

  return (
    <NavbarStyled>
      <div className="avatar-logo">
        <div
          className="logo-bressani-bg"
          style={{ backgroundImage: `url(${theme === 'light-mode' ? logoBressaniDevLight : logoBressaniDevDark}` }}
        ></div>
        <div
          className="logo-bg"
          style={{ backgroundImage: `url(${logoBg})` }}
        ></div>
        <div
          className="logo-rotate"
          style={{ backgroundImage: `url(${logo})` }}
        ></div>
      </div>
      {username === '' ? <ul className="nav-items">
        <li>
          <NavLink
            to="/"
            exact
            activeClassName="active-menu"
            onClick={() => window.location.reload()}>Accueil</NavLink>
        </li>
      </ul> : <ul className="nav-items">
        <li>
          <NavLink
            to="/"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>Accueil</NavLink>
        </li>
        <li>
          <NavLink
            to="/saisie-theme"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>Saisie thème astral</NavLink>
        </li>
        <li>
          <NavLink
            to="/theme"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>Thème astral</NavLink>
        </li>
        <li>
          <NavLink
            to="/le-tirage-sephiroth"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>Sephiroth</NavLink>
        </li>
        <li>
          <NavLink
            to="/le-tirage-astrologique"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>Astromancie</NavLink>
        </li>
      </ul>
      }
      <footer>
        <p>astrologie.bressani.dev</p>
        <p>
          {username === '' ? '' : <div>
            <span className="connecte">
              <div>
                Connecté: {username} <ExitToAppIcon className="icon-logout" onClick={logout}/>
              </div>
            </span>
            <br/>
          </div>
          }
          <span className="syntese">Synthèse à usage personnel (Stéphane Bressani)</span>
        </p>
      </footer>
    </NavbarStyled>
  )
}

const NavbarStyled = styled.header`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-right: 2px solid var(--border-color);

  .avatar-logo {
    width: 100%;
    height: 25%;
    /*
    border-bottom: 1px solid var(--border-color);
    */
    text-align: center;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 90%;
      border-radius: 50%;
      border: 8px solid var(--border-color);
    }
  }

  .nav-items {
    width: 100%;
    text-align: center;

    .active-menu {
      color: var(--primary-color);
      font-size: 1.1rem;
    }

    li {
      display: block;

      a {
        font-size: 1rem;
        text-transform: uppercase;
        transition: all .4s ease-in-out;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0.45rem 0;
        display: block;

        &:hover {
          cursor: pointer;
          color: var(--primary-color);
        }
      }
    }
  }

  footer {
    /*border-top:1px solid var(--border-color);*/
    width: 100%;

    p {
      padding: .3rem 0;
      font-size: 0.9rem;
      text-align: center;
    }
  }

  .syntese {
    color: var(--primary-color);
    font-size: 0.4rem;
    text-align: center;
    margin-top: 0;
  }

  .connecte {
    font-size: 0.7rem;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
  }

  .icon-logout {
    vertical-align: middle;
    cursor: alias;
  }
`

export default Navbar
