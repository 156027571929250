import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import { AstroLayout } from '../styles/AstroLayout'
import { API } from '../data/Api'
import Loading from '../components/Loading'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { selectToken } from '../app/features/authSlice'
import { CgiBodiesAssets, CgiAspectsAssets, CgiGrille, CgiAspect, CgiThemeAstral } from '../data/Cgi'
import { Link, useHistory } from 'react-router-dom'
import { AspectInterface } from '../interfaces/fcgi/v1/FcgiJsonAspect'
import { AspectAssetInterface } from '../interfaces/fcgi/v1/FcgiJsonAspectsAsset'
import moment from 'moment'
import 'moment/locale/fr-ch'

type ThemePageProps = {
  theme: string
}

function ThemePage({ theme }: ThemePageProps): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)
  const [natal, setNatal] = useState({ date: '1984-04-01', time: '00:00', address: '', country: '', gmt: 999, lat: 0, lng: 0 })
  const [ta, setTa] = useState({
    chart: '',
    astre: [{
      lien: '',
      ancre: '',
      nom: '',
      astre: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        asset: ['', '']
      },
      astre_r: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        asset: ['', '']
      }
    }],
    signe: [{
      lien: '',
      ancre: '',
      nom: '',
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      asset: ['', '']
    }],
    house: [{
      lien: '',
      ancre: '',
      width: 0,
      height: 0,
      x: 0,
      y: 0,
      asset: ['', '']
    }]
  })
  const [grille, setGrille] = useState({ grille: '' })
  const [bodiesAssets, setBodiesAssets] = useState([{ asset: '', id: 0, name: '', left: '', top: '' }])
  const [aspectsAssets, setAspectsAssets] = useState<AspectAssetInterface[]>([])
  const [aspects, setAspects] = useState([])
  // JSON_BODIES_ASSET
  const token = useSelector(selectToken)
  const history = useHistory()

  useEffect(() => {
    if (token !== '') {
      httpGetNatal()
    }
  }, [])

  // watcher
  useEffect(() => {
    if (natal.gmt !== 999) {
      axios.request({
        method: 'get',
        url: CgiGrille()
      }).then(async(response) => {
        const data = await response
        setGrille(data.data)
        axios.request({
          method: 'get',
          url: CgiBodiesAssets()
        }).then(async(response) => {
          const data = await response
          const arr = data.data.bodie.slice()
          setBodiesAssets(arr.slice())
          axios.request({
            method: 'get',
            url: CgiAspectsAssets(theme)
          }).then(async(response) => {
            const data = await response
            const arr = data.data.aspect.slice()
            setAspectsAssets(arr.slice())
            axios.request({
              method: 'get',
              url: CgiAspect(natal.date, natal.time, natal.gmt, natal.lat, natal.lng, theme)
            }).then(async(response) => {
              const data = await response
              const arr = data.data.aspect.slice()
              setAspects(arr.slice())
              axios.request({
                method: 'get',
                url: CgiThemeAstral(natal.date, natal.time, natal.gmt, natal.lat, natal.lng, theme)
              }).then(async(response) => {
                const data = await response
                setTa(data.data)
                setSwLoaded(true)
              }).catch((err) => {
                setSwLoaded(true)
                console.error(err)
                toast.error(<p>Erreur de chargement des données cgi theme astral:<br />{err.message}</p>)
              })
            }).catch((err) => {
              setSwLoaded(true)
              console.error(err)
              toast.error(<p>Erreur de chargement des données cgi bodies:<br />{err.message}</p>)
            })
          }).catch((err) => {
            setSwLoaded(true)
            console.error(err)
            toast.error(<p>Erreur de chargement des données cgi bodies:<br />{err.message}</p>)
          })
        }).catch((err) => {
          setSwLoaded(true)
          console.error(err)
          toast.error(<p>Erreur de chargement des données cgi bodies:<br />{err.message}</p>)
        })
      }).catch((err) => {
        setSwLoaded(true)
        console.error(err)
        toast.error(<p>Erreur de chargement des données cgi grille:<br />{err.message}</p>)
      })
    }
  }, [natal])

  const httpGetNatal = async() => {
    axios.request({
      method: 'get',
      url: API + 'chart/show',
      headers: { 'Content-Type': 'application/json', Authorization: token }
    }).then(async(response) => {
      const data = await response
      setNatal(data.data)
    }).catch((err) => {
      setSwLoaded(true)
      console.error(err)
      toast.error(<p>Erreur de chargement des données "chart/show/":<br />{err.message}</p>)
      setTimeout(function() {
        history.push('/saisie-theme')
      }, 1000)
    })
  }

  const maisonLink = () => {
    return (
      <div>
        { ta.house.map((item, index) => {
          return (
            <Link
              to={item.lien + item.ancre}
              key={index}
              className="overlay-link"
              rel="noopener noreferrer"
              title={'Maison ' + (index + 1)}
              style={{
                width: (item.width) + 'px',
                height: (item.height) + 'px',
                top: (item.y) + 'px',
                left: (item.x) + 'px',
                backgroundImage: `url('data:image/svg+xml;base64,${item.asset[theme === 'light-mode' ? 0 : 1]}')`
              }}
            >
            </Link>
          )
        }
        )}
      </div>
    )
  }

  const signeLink = () => {
    return (
      <div>
        { ta.signe.map((item, index) => {
          return (
            <Link
              to={item.lien + item.ancre}
              key={index}
              className="overlay-link"
              rel="noopener noreferrer"
              title={item.nom}
              style={{
                width: (item.width) + 'px',
                height: (item.height) + 'px',
                top: (item.y) + 'px',
                left: (item.x) + 'px',
                backgroundImage: `url('data:image/svg+xml;base64,${item.asset[theme === 'light-mode' ? 0 : 1]}')`
              }}
            >
            </Link>
          )
        }
        )}
      </div>
    )
  }

  const astreLink = () => {
    return (
      <div>
        { ta.astre.map((item, index) => {
          return (
            <Link
              to={item.lien + item.ancre}
              key={index}
              className="overlay-link"
              rel="noopener noreferrer"
              title={item.nom}
              style={{
                width: (item.astre.width) + 'px',
                height: (item.astre.height) + 'px',
                top: (item.astre.y) + 'px',
                left: (item.astre.x) + 'px',
                backgroundImage: `url('data:image/svg+xml;base64,${item.astre.asset[theme === 'light-mode' ? 0 : 1]}')`
              }}
            >
            </Link>
          )
        }
        )}
      </div>
    )
  }

  const astreRLink = () => {
    return (
      <div>
        { ta.astre.map((item, index) => {
          return (
            <Link
              to={item.lien + item.ancre}
              key={index}
              className="overlay-link"
              rel="noopener noreferrer"
              title={item.nom}
              style={{
                width: (item.astre_r.width) + 'px',
                height: (item.astre_r.height) + 'px',
                top: (item.astre_r.y + item.astre_r.width) + 'px',
                left: (item.astre_r.x + item.astre_r.height) + 'px',
                backgroundImage: `url('data:image/svg+xml;base64,${item.astre_r.asset[theme === 'light-mode' ? 0 : 1]}')`
              }}
            >
            </Link>
          )
        }
        )}
      </div>
    )
  }

  const bodieLink = () => {
    return (
      <div>
        { bodiesAssets.map((item, index) => {
          let jsxReturn
          if (item.id !== 98 && item.id !== 99) {
            jsxReturn = (
              <a
                key={index}
                href="#"
                className="overlay-link-astre"
                rel="noopener noreferrer"
                title={item.name}
                style={{
                  top: (item.top) + 'px',
                  left: (item.left) + 'px',
                  backgroundImage: `url('data:image/svg+xml;base64,${item.asset}')`
                }}
              >
              </a>
            )
          } else if (item.id === 98) { // Asc
          } else if (item.id === 99) { // Mc
          }
          return jsxReturn
        }
        )}
      </div>
    )
  }

  const aspectLink = () => {
    const elements = []
    let k = 0
    for (let i = 0; i < aspects.length; i++) {
      const aspect: AspectInterface = aspects[i]
      let top = bodiesAssets[i].top + 5
      const left = bodiesAssets[i].left + 5
      for (let j = 0; j < aspect.liens.length; j++) {
        top += 25
        const lien = aspect.liens[j]
        if (lien.aspect_id !== null) {
          k++
          elements.push(
            <a
              key={k}
              href="#"
              className="overlay-link-aspect"
              rel="noopener noreferrer"
              title={aspectsAssets[lien.aspect_id].name}
              style={{
                top: top + 'px',
                left: left + 'px',
                backgroundImage: `url('data:image/svg+xml;base64,${aspectsAssets[lien.aspect_id].asset}')`
              }}
            >
            </a>
          )
        }
      }
    }
    return (<div>{elements}</div>)
  }

  return (
    !swLoaded ? <Loading /> : <ChartStyled>
      <AstroLayout>
        {/* Les astres Here */}
        <Title title={'Thème'} span={'astral'}/>
        {/* Les astres Here */}
        {/* Thème astral */}
        <InnerLayout className="astro-section">
          <div
            className="image-part-theme"
            style={{
              backgroundImage: `url('data:image/svg+xml;base64,${ta.chart[theme === 'light-mode' ? 0 : 1]}')`
            }}
            title="Thème astral"
          >
            { maisonLink() }
            { signeLink() }
            { astreLink() }
            { astreRLink() }
          </div>
          <div className="info-part">
            <h4>Informations</h4>
            <p>
              Date: {moment(natal.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}<br />
              Heure: {moment(natal.time, 'HH:mm').format('HH:mm')}<br />
              Ville: {natal.address}<br />
              Pays: {natal.country}<br />
              Latitude: {natal.lat}<br />
              Longitude: {natal.lng}<br />
              Gmt: {natal.gmt}<br />
            </p>
          </div>
        </InnerLayout>
        {/* Thème astral End Here */}
        <br />
        {/* Aspect du thème astral */}
        <InnerLayout className="astro-section">
          <div
            className="image-part-grille"
            style={{
              backgroundImage: `url('data:image/svg+xml;base64,${grille.grille[theme === 'light-mode' ? 0 : 1]}')`
            }}
            title="Grille des aspects"
          >
            { bodieLink() }
            { aspectLink() }
          </div>
        </InnerLayout>
        {/* Aspect du thème astral End Here */}
      </AstroLayout>
    </ChartStyled>
  )
}

const ChartStyled = styled.div`
  .image-part-theme {
    position: relative;
    width: 600px;
    height: 600px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0;
  }
  
  .overlay-link {
    display: block;
    position: absolute;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    text-decoration: none; /* Pour éliminer tout soulignement ou style par défaut des liens */
  }
  
  .image-part-grille {
    position: relative;
    width: 376px;  /* Largeur du SVG grille 25 */
    height: 401px; /* Hauteur du SVG grille 25 */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0;
  }

  .overlay-link-astre {
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: cover;
    text-decoration: none; /* Pour éliminer tout soulignement ou style par défaut des liens */
  }
  
  .overlay-link-aspect {
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    text-decoration: none; /* Pour éliminer tout soulignement ou style par défaut des liens */
  }
`

export default ThemePage
