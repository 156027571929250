import { createSlice } from '@reduxjs/toolkit'

interface RootState {
  auth: {
    token: string;
    name: string;
    err: string;
  };
}

const initialState = {
  token: '',
  name: '',
  err: ''
}
export const authSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    changeToken: (state, action) => {
      state.token = action.payload
    },
    changeName: (state, action) => {
      state.name = action.payload
    },
    changeErr: (state, action) => {
      state.err = action.payload
    }
  }
})

export const {
  changeToken,
  changeName,
  changeErr
} = authSlice.actions

export const selectToken = (state: RootState): string => state.auth.token
export const selectName = (state: RootState): string => state.auth.name
export const selectErr = (state: RootState): string => state.auth.err

export default authSlice.reducer
