import styled from 'styled-components'

export const AstroLayout = styled.div`
  /* fade */
  -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
  animation: my-fade 1s;
  .astro-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .8rem;
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  }

  .astro-section-sans-image {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: .8rem;
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  }
  
  .image-part {
    height: 100%;
    width: 100%;

    img {
      width: 90%;
      height: 90%;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 599px) {
    .image-part {
      height: 330px !important;
      width: 330px !important;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .image-part {
      height: 430px !important;
      width: 430px !important;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1599px) {
    .image-part {
      height: 400px !important;
      width: 400px !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .image-part {
      height: 600px !important;
      width: 600px !important;
    }
  }

  .info-part-sans-image {
    h4 {
      color: var(--primary-color);
      font-size: 1.3rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1.9rem;

      span {
        color: var(--primary-color);
      }
    }

    .span-color {
      color: var(--primary-color) !important;
    }
  }

  .info-part {
    h4 {
      color: var(--primary-color);
      font-size: 1.3rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1.9rem;

      span {
        color: var(--primary-color);
      }
    }

    p {
      font-size: .9rem;
    }

    .information {
      margin: .5rem 0;
      width: 90%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 500px) {
        width: 100%;
      }

      ul {
        li {
          margin: .5rem 0;
          @media screen and (max-width: 500px) {
            margin: 1rem 0;
            font-size: .9rem;
          }
        }
      }

      .details {
        color: var(--paragraph-color);
      }
    }

    .span-color {
      color: var(--primary-color) !important;
    }
  }
`
