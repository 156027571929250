import React, { useEffect, useState } from 'react'
import { style } from 'glamor'
import styled from 'styled-components'
import { AstroLayout } from '../styles/AstroLayout'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'
import axios from 'axios'
import { API } from '../data/Api'
import { selectToken } from '../app/features/authSlice'
import { useSelector } from 'react-redux'
import moment from 'moment'
import 'moment/locale/fr-ch'
import Loading from '../components/Loading'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DateInterface, MaisonInterface } from '../interfaces/v1/Astromancie'

type LeTirageAstrologiquePageProps = {
  theme: string
}

function LeTirageAstrologiquePage({ theme }: LeTirageAstrologiquePageProps): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)
  const [tirages, setTirages] = useState([])
  const [cartes, setCartes] = useState<MaisonInterface[]>([
    {
      id: 0,
      id_maison: 0,
      sens: true,
      dc01_ti_astro: { date: '' },
      da04_tarot_carte: { id: 0, nom: '', chiffre_romain: '', id_carte: '00', svg_light: '', svg_dark: '' }
    }
  ])
  const token = useSelector(selectToken)

  const inputsHandlerSelectTirage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target
    selectTiAstro(value)
  }

  const selectTiAstro = async(t: string) => {
    axios.request({
      method: 'get',
      url: API + 'astromancie/show/' + t,
      headers: { 'Content-Type': 'application/json', Authorization: token }
    })
      .then(async(response) => {
        const data = await response.data
        setCartes(data)
      }).catch((err) => {
        setSwLoaded(true)
        console.error(err)
        toast.error(<p>Erreur de chargement des données "astromancie/show/":<br />{err.message}</p>)
      })
  }

  const httpGetTirage = async() => {
    axios.request({
      method: 'get',
      url: API + 'astromancie/all',
      headers: { 'Content-Type': 'application/json', Authorization: token }
    }).then(async(response) => {
      const data = await response
      setTirages(data.data)
      await selectTiAstro(data.data[data.data.length - 1].id)
    }).catch((err) => {
      setSwLoaded(true)
      console.error(err)
      toast.error(<p>Erreur de chargement des données "astromancie/all":<br />{err.message}</p>)
    })
  }

  useEffect(() => {
    if (token !== '') {
      httpGetTirage().then(() => setSwLoaded(true))
    }
  }, [])

  const styleCarteDansLeBonSens = (sens: boolean) => {
    if (sens) {
      return style({
        // cursor: 'cursor',
      })
    } else {
      return style({
        // cursor: 'all-scroll', (sur iPad ca fait bizzare)
        ':hover':
          {
            transform: 'rotate(180deg)',
            top: '-4px'
          }
      })
    }
  }

  const tirageSelect = () => {
    moment.locale('fr-ch')
    return (
      <select
        name="tirage"
        id="tirage"
        onChange={inputsHandlerSelectTirage}
      >
        {
          [...tirages].reverse().map((m: DateInterface, i) => { // copie [...tirage] pour reverse
            const originalIndex = tirages.length - 1 - i
            const date = moment(m.date, 'YYYY-MM-DD').format('YYYY')
            const d = date.toString()
            if (tirages.length === 1) {
              return (
                <option value={m.id}>Tirage {d}</option>
              )
            } else {
              return (
                <option value={m.id}>Tirage {originalIndex + 1} - {d}</option>
              )
            }
          })
        }
      </select>
    )
  }

  const maison = (el: MaisonInterface) => {
    let nom = el.da04_tarot_carte.nom
    if (el.da04_tarot_carte.chiffre_romain !== '') {
      nom = el.da04_tarot_carte.chiffre_romain + ' ' + nom
    }
    return (
      <span className="flip-box" key={el.id}>
        <span className="flip-box-inner" {...styleCarteDansLeBonSens(el.sens)}>
          <span className="flip-box-front">
            <img
              className={el.sens ? 'tarot-carte' : 'tarot-carte tarot-carte-a-l-envers'}
              src={
                theme === 'light-mode' ? 'data:image/svg+xml;base64,' + el.da04_tarot_carte.svg_dark : 'data:image/svg+xml;base64,' + el.da04_tarot_carte.svg_light}
              alt={nom}
              title={nom}
            />
          </span>
        </span>
      </span>
    )
  }

  const MAISON_LIGNE = [[1, 2, 3, 4, 5, 6], [12, 11, 10, 9, 8, 7]]

  return (
    !swLoaded ? <Loading /> : <LeTirageAstrologiqueStyled>
      <AstroLayout>
        <ToastContainer theme={theme === 'light-mode' ? 'light' : 'dark'}/>
        {/* Les maisons Here */}
        <Title title={'Le tirage'} span={'astrologique'}/>
        {/* Les maisons Here */}

        {/* Structure binaire */}
        <InnerLayout className="astro-section-sans-image">
          <div className="info-part-sans-image">
            <h4>KRISS HADAR</h4>
            <p>
              Je propose de l'astromancie selon la méthode de <span className="span-color">Kriss Hadar</span>.
              Il y a d'autre méthode comme celle écrite dans le livret BP Grimaud du&nbsp;
              <span className="span-color">Tarot de Marseille</span>.
            </p>
            <br/>
            <h4>Tirage astrologique</h4>
            <br/>
            <form onSubmit={(e) => {
              e.preventDefault()
            }} className="form-part">
              <div className="form-group">
                {tirageSelect()}
              </div>
            </form>
            <br/>
            <p>
              <table className="maisons-table">
                <tbody className="table-border">
                  {
                    MAISON_LIGNE[0].map(m => (
                      <th className="table-border td-options">
                        <div className="td-margin">
                          <div className="text-center-title">M{m}</div>
                        </div>
                      </th>))
                  }
                </tbody>
                <tr className="table-border">
                  {
                    MAISON_LIGNE[0].map(m => (
                      <td className="table-border td-options">
                        <div className="td-margin">
                          {
                            cartes.filter(el => el.id_maison === m).map(el => maison(el))
                          }
                        </div>
                      </td>))
                  }
                </tr>
                <tr className="table-border">
                  {
                    MAISON_LIGNE[1].map(m => (
                      <td className="table-border td-options">
                        <div className="td-margin">
                          {
                            cartes.filter(el => el.id_maison === m).map(el => maison(el))
                          }
                        </div>
                      </td>))
                  }
                </tr>
                <tfoot className="table-border">
                  {
                    MAISON_LIGNE[1].map(m => (
                      <th className="table-border td-options">
                        <div className="td-margin">
                          <div className="text-center-title">M{m}</div>
                        </div>
                      </th>))
                  }
                </tfoot>
              </table>
            </p>
          </div>
        </InnerLayout>
        {/* Structure binaire Here */}
      </AstroLayout>
    </LeTirageAstrologiqueStyled>
  )
}

const LeTirageAstrologiqueStyled = styled.div`
  .image-part {
    height: 100%;
    width: 100%;

    img {
      width: 90%;
      height: 90%;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 599px) {
    .image-part {
      height: 330px !important;
      width: 330px !important;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .image-part {
      height: 430px !important;
      width: 430px !important;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1599px) {
    .image-part {
      height: 500px !important;
      width: 500px !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .image-part {
      height: 600px !important;
      width: 600px !important;
    }
  }

  .info-part-sans-image {
    h4 {
      color: var(--primary-color);
      font-size: 1.3rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1.9rem;

      span {
        color: var(--primary-color);
      }
    }

    .span-color {
      color: var(--primary-color) !important;
    }
  }

  .info-part {
    h4 {
      color: var(--primary-color);
      font-size: 1.3rem;
    }

    h3 {
      margin: 1rem 0;
      font-size: 1.9rem;

      span {
        color: var(--primary-color);
      }
    }

    p {
      font-size: .9rem;
    }

    .information {
      margin: .5rem 0;
      width: 90%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 500px) {
        width: 100%;
      }

      ul {
        li {
          margin: .5rem 0;
          @media screen and (max-width: 500px) {
            margin: 1rem 0;
            font-size: .9rem;
          }
        }
      }

      .details {
        color: var(--paragraph-color);
      }
    }

    .span-color {
      color: var(--primary-color) !important;
    }
  }

  .maisons-table {
    width: 1024px;
    border: 5px solid var(--primary-color);
    background-color: var(--sidebar-color);
    border-radius: 12px;

    div {
      font-size: .7rem;
    }
  }

  .table-border {
    border: 0;
    //border: 2px solid var(--primary-color);
  }

  .td-options {
    vertical-align: center;
  }

  .td-options-bottom {
    vertical-align: bottom;
    background: var(--sidebar-color);
  }

  .td-options-top {
    vertical-align: top;
    background: var(--sidebar-color);
  }

  .td-margin {
    //margin-bottom: 1rem;
    //margin-top: 1rem;
  }

  .text-center {
    text-align: center !important;
  }

  .text-center-title {
    text-align: center !important;
    color: var(--primary-color) !important;
  }

  .tarot-carte {
    width: 100%;
    height: 100%;
  }

  .tarot-carte-a-l-envers {
    -webkit-transform: scale(-1, -1);
    transform: scale(-1, -1);
  }

  .flip-box {
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
    display: block;
  }

  .flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    display: block;
  }

  .flip-box-front .flip-box-back {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
`

export default LeTirageAstrologiquePage
