import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AstroLayout } from '../styles/AstroLayout'
import { HomeLayout } from '../styles/HomeLayout'
import Particle from '../components/Particle'
import Loading from '../components/Loading'

function HomePage(): JSX.Element {
  const [swLoaded, setSwLoaded] = useState(false)

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  return (
    !swLoaded ? <Loading /> : <HomePageStyled>
      <AstroLayout>
        <HomeLayout>
          <Particle/>
          <div className="typography">
            <h1>Astrologie traditionnelle par <span>bressani.dev</span></h1>
          </div>
        </HomeLayout>
      </AstroLayout>
    </HomePageStyled>
  )
}

const HomePageStyled = styled.div`
`

export default HomePage
