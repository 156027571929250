const CGI = 'https://cgi.bressani.dev/astro.fcgi'

function CgiAspect(date: string, time: string, gmt: number, lat: number, lng: number, theme: string): string {
  const url = new URL(CGI)
  const [year, month, day] = date.split('-').map(segment => +segment)
  const [hour, min] = time.split(':').map(segment => +segment)
  const params: { [key: string]: string | number } = {
    year,
    month,
    day,
    hour,
    min,
    gmt,
    lat,
    lng,
    color: theme === 'light-mode' ? 0 : 1,
    option_api_v2: 'JSON_ASPECT'
  }
  Object.keys(params).forEach(key => {
    url.searchParams.append(key, params[key].toString())
  })
  return url.toString()
}
function CgiThemeAstral(date: string, time: string, gmt: number, lat: number, lng: number, theme: string): string {
  const url = new URL(CGI)
  const [year, month, day] = date.split('-').map(segment => +segment)
  const [hour, min] = time.split(':').map(segment => +segment)
  const aspectOption = '0,1,2,3,4,5,6,7,8,9,11,98,99'
  const params: { [key: string]: string | number } = {
    year,
    month,
    day,
    hour,
    min,
    gmt,
    lat,
    lng,
    color: theme === 'light-mode' ? 0 : 1,
    aspect_option: aspectOption,
    option_api_v2: 'JSON_CHART_GRID'
  }
  Object.keys(params).forEach(key => {
    url.searchParams.append(key, params[key].toString())
  })
  return url.toString()
}

function CgiGrille(): string {
  const url = new URL(CGI)
  const params: { [key: string]: string | number } = {
    option_api_v2: 'JSON_GRID'
  }
  Object.keys(params).forEach(key => {
    url.searchParams.append(key, params[key].toString())
  })
  return url.toString()
}
function CgiBodiesAssets(): string {
  const url = new URL(CGI)
  const params: { [key: string]: string | number } = {
    option_api_v2: 'JSON_BODIES_ASSET'
  }
  Object.keys(params).forEach(key => {
    url.searchParams.append(key, params[key].toString())
  })
  return url.toString()
}

function CgiAspectsAssets(theme: string): string {
  const url = new URL(CGI)
  const params: { [key: string]: string | number } = {
    color: theme === 'light-mode' ? 0 : 1,
    option_api_v2: 'JSON_ASPECTS_ASSET'
  }
  Object.keys(params).forEach(key => {
    url.searchParams.append(key, params[key].toString())
  })
  return url.toString()
}

export { CGI, CgiThemeAstral, CgiGrille, CgiBodiesAssets, CgiAspectsAssets, CgiAspect }
