import React from 'react'
import styled from 'styled-components'
import Title from '../components/Title'
import { InnerLayout } from '../styles/Layouts'

function AboutPage(): JSX.Element {
  return (
    <AboutPageStyled>
      {/* about Start Here */}
      <Title title={'A'} span={'Propos'}/>
      {/* About End Here */}

      {/* Licenses de ce site Start Here */}
      <div className="apropos">
        <InnerLayout>
          La calculation des astres et maisons en astrologie ce fait en partie grace à la librairie
          <a
            href="https://www.astro.com/swisseph/swephinfo_e.htm"
            target="_blank"
          >
            swissephem
          </a>.<br/>
          Une partie du design du logo en haut à gauche a été crée à partir d'une modification de l'image de onfocus
          sur <a href='https://fr.freepik.com/' target='_blank'>Freepik</a>.<br/>
          Le design de ma photo sur cette page a été créer à partir de l'image de vikayatskina sur
          <a
            href='https://fr.freepik.com/'
            target='_blank'
          >
            Freepik
          </a>.
        </InnerLayout>
      </div>
      {/* Licenses de ce site End Here */}

    </AboutPageStyled>
  )
}

const AboutPageStyled = styled.div`
  .about-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: .8rem;
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }

    .image-part {
      height: 100%;
      width: 100%;

      img {
        width: 90%;
        height: 90%;
        border: 2px solid var(--border-color);
      }
    }

    .info-part {
      h4 {
        color: var(--primary-color);
        font-size: 1.3rem;
      }

      h3 {
        margin: 1rem 0;
        font-size: 1.9rem;

        span {
          color: var(--primary-color);
        }
      }

      p {
        font-size: .9rem;
      }

      .information {
        margin: .5rem 0;
        width: 90%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 500px) {
          width: 100%;
        }

        ul {
          li {
            margin: .5rem 0;
            @media screen and (max-width: 500px) {
              margin: 1rem 0;
              font-size: .9rem;
            }
          }
        }

        .details {
          color: var(--paragraph-color);
        }
      }

    }
  }

  .rec-arrow {
    background-color: var(--border-color);

    &:hover {
      background-color: var(--primary-color);
    }

    &:active, &:focus {
      background-color: var(--primary-color);
    }
  }

  .rec-dot_active {
    box-shadow: none;
    background-color: var(--primary-color);
  }

  @media screen and (max-width: 500px) {
    .rec.rec-arrow {
      display: none;
    }
  }
`

export default AboutPage
